import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        cuentas: [],
        cuenta: []
    }),
    mutations: {
        createCuenta (state, item){
            state.cuentas.push(item)
        },
        updateCuenta (state, item){
            let pos = state.cuentas.findIndex(e => e.id == item.id)
            state.cuentas.splice(pos, 1, item)
        },
        deleteCuenta(state, index){
            state.cuentas.splice(index, 1)
        },
        showCuenta(state, cuenta){
            let pos = state.cuentas.findIndex(e => e.id == cuenta.id)
            cuenta.mostrar = 1
            state.cuentas.splice(pos, 1, cuenta)
        },
        hideCuenta(state, cuenta){
            let pos = state.cuentas.findIndex(e => e.id == cuenta.id)
            cuenta.mostrar = 0
            state.cuentas.splice(pos, 1, cuenta)
        },
        setCuentas(state, cuentas){
            state.cuentas = cuentas
        }
    },
    actions: {
        async createCuenta (context, cuenta){
            try{
                const data = await axios.post(context.rootState.base_url + 'cuentas', context.rootState.headers)
                context.commit("createCuenta", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateCuenta (context, cuenta){
            try{
                const data = await axios.put(context.rootState.base_url + `cuentas/${cuenta.id}`, context.rootState.headers)
                context.commit("updateCuenta", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteCuenta(context, cuenta){
            try{
                const data = await axios.delete(context.rootState.base_url + `cuentas/${cuenta.id}`, context.rootState.headers)
                context.commit("deleteCuenta", cuenta.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchCuentas(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'cuentas', context.rootState.headers)
                context.commit("setCuentas", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.cuentas.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.cuentas.find(e => e.id == id)
        },
        getAll: state => {
            return state.cuentas
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */