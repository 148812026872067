import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        plantas: [],
        planta: []
    }),
    mutations: {
        createPlanta (state, item){
            state.plantas.push(item)
        },
        updatePlanta (state, item){
            let pos = state.plantas.findIndex(e => e.id == item.id)
            state.plantas.splice(pos, 1, item)
        },
        deletePlanta(state, index){
            state.plantas.splice(index, 1)
        },
        showPlanta(state, planta){
            let pos = state.plantas.findIndex(e => e.id == planta.id)
            planta.mostrar = 1
            state.plantas.splice(pos, 1, planta)
        },
        hidePlanta(state, planta){
            let pos = state.plantas.findIndex(e => e.id == planta.id)
            planta.mostrar = 0
            state.plantas.splice(pos, 1, planta)
        },
        setPlantas(state, plantas){
            state.plantas = plantas
        }
    },
    actions: {
        async createPlanta (context, planta){
            try{
                const data = await axios.post(context.rootState.base_url + 'plantas', context.rootState.headers)
                context.commit("createPlanta", data)
            }catch(error){
                console.log(error)
            }
        },
        async updatePlanta (context, planta){
            try{
                const data = await axios.put(context.rootState.base_url + `plantas/${planta.id}`, context.rootState.headers)
                context.commit("updatePlanta", data)
            }catch(error){
                console.log(error)
            }
        },
        async deletePlanta(context, planta){
            try{
                const data = await axios.delete(context.rootState.base_url + `plantas/${planta.id}`, context.rootState.headers)
                context.commit("deletePlanta", planta.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchPlantas(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'plantas', context.rootState.headers)
                context.commit("setPlantas", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.plantas.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.plantas.find(e => e.id == id)
        },
        getAll: state => {
            return state.plantas
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */