var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header card-header-primary card-header-icon"},[_c('div',{class:("card-icon " + _vm.color_icono)},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.icono),expression:"icono"}],staticClass:"material-icons"},[_vm._v(_vm._s(_vm.icono))])]),_c('h4',{staticClass:"card-title"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.label,"single-line":"","hide-details":""},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}})],1)])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"toolbar"}),_c('div',{staticClass:"material-datatables"},[_c('v-data-table',{attrs:{"headers":_vm.cabeceras,"items":_vm.items,"search":_vm.busqueda,"header-props":{ sortIcon: 'arrow_upward' },"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'navigate_befores',
            nextIcon: 'navigate_next'
            //'items-per-page-options':[5,10,20],
          }},scopedSlots:_vm._u([{key:"item.firma",fn:function(ref){
          var item = ref.item;
return [(item.firma != null && item.firma.length > 0)?_c('img',{attrs:{"src":'data:image/jpeg;base64,'+item.firma64,"width":"240","height":"120"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('i',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.buscar_accion('editar')),expression:"buscar_accion('editar')"}],staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"orange"},on:{"click":function($event){return _vm.$emit('editar',item)}}},on),[_vm._v("edit")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('i',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.buscar_accion('subir')),expression:"buscar_accion('subir')"}],staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"#558B2F"},on:{"click":function($event){return _vm.$emit('subir',item)}}},on),[_vm._v("upload")])]}}],null,true)},[_c('span',[_vm._v("Cargar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('i',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.buscar_accion('mostrar') && item.mostrar != 1),expression:"buscar_accion('mostrar') && item.mostrar != 1"}],staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"#0277BD"},on:{"click":function($event){return _vm.$emit('mostrar',item)}}},on),[_vm._v("visibility")])]}}],null,true)},[_c('span',[_vm._v("Mostrar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('i',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.buscar_accion('ocultar') && item.mostrar == 1),expression:"buscar_accion('ocultar') && item.mostrar == 1"}],staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"#BF360C"},on:{"click":function($event){return _vm.$emit('ocultar',item)}}},on),[_vm._v("visibility_off")])]}}],null,true)},[_c('span',[_vm._v("Ocultar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('i',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.buscar_accion('configurar')),expression:"buscar_accion('configurar')"}],staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"gray"},on:{"click":function($event){return _vm.$emit('configurar',item)}}},on),[_vm._v("settings")])]}}],null,true)},[_c('span',[_vm._v("Configurar")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('i',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.buscar_accion('eliminar')),expression:"buscar_accion('eliminar')"}],staticClass:"material-icons",staticStyle:{"cursor":"pointer","color":"red"},on:{"click":function($event){return _vm.$emit('eliminar',item)}}},on),[_vm._v("delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}},(_vm.tachar)?{key:"item.empresa.razon_social",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.empresa.razon_social))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.empresa.razon_social))])]}}:null,(_vm.tachar)?{key:"item.nombre",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.nombre))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.nombre))])]}}:null,(_vm.tachar)?{key:"item.latitud",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.latitud))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.latitud))])]}}:null,(_vm.tachar)?{key:"item.longitud",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.longitud))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.longitud))])]}}:null,(_vm.tachar)?{key:"item.rut",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.rut))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.rut))])]}}:null,(_vm.tachar)?{key:"item.razon_social",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.razon_social))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.razon_social))])]}}:null,(_vm.tachar)?{key:"item.giro",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.giro))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.giro))])]}}:null,(_vm.tachar)?{key:"item.direccion",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.direccion))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.direccion))])]}}:null,(_vm.tachar)?{key:"item.fono",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.fono))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.fono))])]}}:null,(_vm.tachar)?{key:"item.email",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.email))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.email))])]}}:null,(_vm.tachar)?{key:"item.logo",fn:function(ref){
          var item = ref.item;
return [(item.logo != null && item.logo.length > 0)?_c('span',[_vm._v("Si")]):_vm._e(),(item.logo == null || item.logo.length == 0)?_c('span',[_vm._v("No")]):_vm._e()]}}:null,(_vm.tachar)?{key:"item.abreviatura",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.abreviatura))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.abreviatura))])]}}:null,(_vm.tachar)?{key:"item.capacidad",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.capacidad))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.capacidad))])]}}:null,(_vm.tachar)?{key:"item.sistema",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.sistema))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.sistema))])]}}:null,(_vm.tachar)?{key:"item.nudos_vacio",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.nudos_vacio))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.nudos_vacio))])]}}:null,(_vm.tachar)?{key:"item.nudos_cargado",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.nudos_cargado))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.nudos_cargado))])]}}:null,(_vm.tachar)?{key:"item.tiempo_carga",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.tiempo_carga))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.tiempo_carga))])]}}:null,(_vm.tachar)?{key:"item.tipo_descarga.nombre",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.tipo_descarga.nombre))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.tipo_descarga.nombre))])]}}:null,(_vm.tachar)?{key:"item.toneladas",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.toneladas))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.toneladas))])]}}:null,{key:"item.m3_bodegas",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(item.m3_bodegas)}})]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.name))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.name))])]}},(_vm.tachar)?{key:"item.wellboat.nombre",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.wellboat?item.wellboat.nombre:''))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.wellboat?item.wellboat.nombre:''))])]}}:null,{key:"item.rol.nombre",fn:function(ref){
          var item = ref.item;
return [(item.mostrar == 1)?_c('p',[_vm._v(_vm._s(item.rol?item.rol.nombre:''))]):_c('p',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.rol?item.rol.nombre:''))])]}}],null,true)})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }