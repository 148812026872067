import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        bodegas: [],
        bodega: []
    }),
    mutations: {
        createBodega (state, item){
            state.bodegas.push(item)
        },
        updateBodega (state, item){
            let pos = state.bodegas.findIndex(e => e.id == item.id)
            state.bodegas.splice(pos, 1, item)
        },
        deleteBodega(state, index){
            state.bodegas.splice(index, 1)
        },
        showBodega(state, bodega){
            let pos = state.bodegas.findIndex(e => e.id == bodega.id)
            bodega.mostrar = 1
            state.bodegas.splice(pos, 1, bodega)
        },
        hideBodega(state, bodega){
            let pos = state.bodegas.findIndex(e => e.id == bodega.id)
            bodega.mostrar = 0
            state.bodegas.splice(pos, 1, bodega)
        },
        setBodegas(state, bodegas){
            state.bodegas = bodegas
        }
    },
    actions: {
        async createBodega (context, bodega){
            try{
                const data = await axios.post(context.rootState.base_url + 'bodegas', context.rootState.headers)
                context.commit("createBodega", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateBodega (context, bodega){
            try{
                const data = await axios.put(context.rootState.base_url + `bodegas/${bodega.id}`, context.rootState.headers)
                context.commit("updateBodega", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteBodega(context, bodega){
            try{
                const data = await axios.delete(context.rootState.base_url + `bodegas/${bodega.id}`, context.rootState.headers)
                context.commit("deleteBodega", bodega.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchBodegas(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'bodegas', context.rootState.headers)
                context.commit("setBodegas", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.bodegas.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.bodegas.find(e => e.id == id)
        },
        getAll: state => {
            return state.bodegas
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */