var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{class:("text-" + _vm.alinear)},[(_vm.mostrar('crear'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"blue darken-1","dark":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.$emit('crear')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"material-icons"},[_vm._v("add")])])]}}],null,false,2168407343)},[_c('span',[_vm._v(_vm._s(_vm.tooltip('crear')))])]):_vm._e(),(_vm.mostrar('cargar'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"green darken-1","dark":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.$emit('cargar')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"material-icons"},[_vm._v("file_upload")])])]}}],null,false,618165138)},[_c('span',[_vm._v(_vm._s(_vm.tooltip('cargar')))])]):_vm._e(),(_vm.mostrar('eliminar'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red darken-2","dark":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.$emit('eliminar')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"material-icons"},[_vm._v("delete")])])]}}],null,false,2924807661)},[_c('span',[_vm._v(_vm._s(_vm.tooltip('eliminar')))])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }