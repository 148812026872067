import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        empresas: [],
        empresa: []
    }),
    mutations: {
        createEmpresa (state, item){
            state.empresas.push(item)
        },
        updateEmpresa (state, item){
            let pos = state.empresas.findIndex(e => e.id == item.id)
            state.empresas.splice(pos, 1, item)
        },
        deleteEmpresa(state, index){
            state.empresas.splice(index, 1)
        },
        showEmpresa(state, empresa){
            let pos = state.empresas.findIndex(e => e.id == empresa.id)
            empresa.mostrar = 1
            state.empresas.splice(pos, 1, empresa)
        },
        hideEmpresa(state, empresa){
            let pos = state.empresas.findIndex(e => e.id == empresa.id)
            empresa.mostrar = 0
            state.empresas.splice(pos, 1, empresa)
        },
        setEmpresas(state, empresas){
            state.empresas = empresas
            
        }
    },
    actions: {
        async createEmpresa (context, empresa){
            try{
                const data = await axios.post(context.rootState.base_url + 'empresas', context.rootState.headers)
                context.commit("createEmpresa", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateEmpresa (context, empresa){
            try{
                const data = await axios.put(context.rootState.base_url + `empresas/${empresa.id}`, context.rootState.headers)
                context.commit("updateEmpresa", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteEmpresa(context, empresa){
            try{
                const data = await axios.delete(context.rootState.base_url + `empresas/${empresa.id}`, context.rootState.headers)
                context.commit("deleteEmpresa", empresa.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchEmpresas(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'empresas', context.rootState.headers)
                context.commit("setEmpresas", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.empresas.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.empresas.find(e => e.id == id)
        },
        getAll: state => {
            return state.empresas
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */