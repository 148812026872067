import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        centros: [],
        centro: []
    }),
    mutations: {
        createCentro (state, item){
            state.centros.push(item)
        },
        updateCentro (state, item){
            let pos = state.centros.findIndex(e => e.id == item.id)
            state.centros.splice(pos, 1, item)
        },
        deleteCentro(state, index){
            state.centros.splice(index, 1)
        },
        showCentro(state, centro){
            let pos = state.centros.findIndex(e => e.id == centro.id)
            centro.mostrar = 1
            state.centros.splice(pos, 1, centro)
        },
        hideCentro(state, centro){
            let pos = state.centros.findIndex(e => e.id == centro.id)
            centro.mostrar = 0
            state.centros.splice(pos, 1, centro)
        },
        setCentros(state, centros){
            state.centros = centros
        }
    },
    actions: {
        async createCentro (context, centro){
            try{
                const data = await axios.post(context.rootState.base_url + 'centros', context.rootState.headers)
                context.commit("createCentro", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateCentro (context, centro){
            try{
                const data = await axios.put(context.rootState.base_url + `centros/${centro.id}`, context.rootState.headers)
                context.commit("updateCentro", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteCentro(context, centro){
            try{
                const data = await axios.delete(context.rootState.base_url + `centros/${centro.id}`, context.rootState.headers)
                context.commit("deleteCentro", centro.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchCentros(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'centros', context.rootState.headers)
                context.commit("setCentros", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.centros.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.centros.find(e => e.id == id)
        },
        getAll: state => {
            return state.centros
        },
        getByEmpresa: (state, empresas_id) => {
            return state.centros.find(c => c.empresas_id == empresas_id)
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */