import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        wellboats: [],
        wellboat: []
    }),
    mutations: {
        createWellboat (state, item){
            state.wellboats.push(item)
        },
        updateWellboat (state, item){
            let pos = state.wellboats.findIndex(e => e.id == item.id)
            state.wellboats.splice(pos, 1, item)
        },
        deleteWellboat(state, index){
            state.wellboats.splice(index, 1)
        },
        showWellboat(state, wellboat){
            let pos = state.wellboats.findIndex(e => e.id == wellboat.id)
            wellboat.mostrar = 1
            state.wellboats.splice(pos, 1, wellboat)
        },
        hideWellboat(state, wellboat){
            let pos = state.wellboats.findIndex(e => e.id == wellboat.id)
            wellboat.mostrar = 0
            state.wellboats.splice(pos, 1, wellboat)
        },
        setWellboats(state, wellboats){
            state.wellboats = wellboats
        }
    },
    actions: {
        async createWellboat (context, wellboat){
            try{
                const data = await axios.post(context.rootState.base_url + 'wellboats', context.rootState.headers)
                context.commit("createWellboat", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateWellboat (context, wellboat){
            try{
                const data = await axios.put(context.rootState.base_url + `wellboats/${wellboat.id}`, context.rootState.headers)
                context.commit("updateWellboat", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteWellboat(context, wellboat){
            try{
                const data = await axios.delete(context.rootState.base_url + `wellboats/${wellboat.id}`, context.rootState.headers)
                context.commit("deleteWellboat", wellboat.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchWellboats(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'wellboats', context.rootState.headers)
                context.commit("setWellboats", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.wellboats.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.wellboats.find(e => e.id == id)
        },
        getAll: state => {
            return state.wellboats
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */