<template>
  <div>
    <v-row >
      <v-col :class="`text-${alinear}`">
        <v-tooltip top v-if="mostrar('crear')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              color="blue darken-1"
              dark
              right
              fab
              small
              @click="$emit('crear')"
              v-bind="attrs"
              v-on="on"
            >
              <!-- <v-icon>mdi-plus</v-icon> -->
              <i class="material-icons">add</i>
            </v-btn>
          </template>
          <span>{{tooltip('crear')}}</span>
        </v-tooltip>

        <v-tooltip top v-if="mostrar('cargar')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              color="green darken-1"
              dark
              right
              fab
              small
              @click="$emit('cargar')"
              v-bind="attrs"
              v-on="on"
            >
              <!-- <v-icon>mdi-file-excel</v-icon> -->
              <i class="material-icons">file_upload</i>
            </v-btn>
          </template>
          <span>{{tooltip('cargar')}}</span>
        </v-tooltip>

        <v-tooltip top v-if="mostrar('eliminar')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red darken-2"
              dark
              right
              fab
              small
              @click="$emit('eliminar')"
              v-bind="attrs"
              v-on="on"
            >
              <!-- <v-icon>mdi-delete</v-icon> -->
              <i class="material-icons">delete</i>
            </v-btn>
          </template>
          <span>{{tooltip('eliminar')}}</span>
        </v-tooltip>

      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
    props:['botones','alinear'],
    methods: {
        mostrar(nombre){
            let boton = this.botones.find(b => b.boton == nombre);
            return boton? true : false;
        },
        tooltip(nombre){
            let boton = this.botones.find(b => b.boton == nombre);
            return boton? boton.tooltip : '';
        }
    },
}
</script>