import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        monedas: [],
        moneda: []
    }),
    mutations: {
        createMoneda (state, item){
            state.monedas.push(item)
        },
        updateMoneda (state, item){
            let pos = state.monedas.findIndex(e => e.id == item.id)
            state.monedas.splice(pos, 1, item)
        },
        deleteMoneda(state, index){
            state.monedas.splice(index, 1)
        },
        showMoneda(state, moneda){
            let pos = state.monedas.findIndex(e => e.id == moneda.id)
            moneda.mostrar = 1
            state.monedas.splice(pos, 1, moneda)
        },
        hideMoneda(state, moneda){
            let pos = state.monedas.findIndex(e => e.id == moneda.id)
            moneda.mostrar = 0
            state.monedas.splice(pos, 1, moneda)
        },
        setMonedas(state, monedas){
            state.monedas = monedas
        }
    },
    actions: {
        async createMoneda (context, moneda){
            try{
                const data = await axios.post(context.rootState.base_url + 'monedas', context.rootState.headers)
                context.commit("createMoneda", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateMoneda (context, moneda){
            try{
                const data = await axios.put(context.rootState.base_url + `monedas/${moneda.id}`, context.rootState.headers)
                context.commit("updateMoneda", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteMoneda(context, moneda){
            try{
                const data = await axios.delete(context.rootState.base_url + `monedas/${moneda.id}`, context.rootState.headers)
                context.commit("deleteMoneda", moneda.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchMonedas(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'monedas', context.rootState.headers)
                context.commit("setMonedas", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.monedas.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.monedas.find(e => e.id == id)
        },
        getAll: state => {
            return state.monedas
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */